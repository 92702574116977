import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal bg_white flex_centered column" }
const _hoisted_2 = { class: "page_padding text-center" }
const _hoisted_3 = { class: "w-100 px-3 my-4" }
const _hoisted_4 = { class: "page_padding flex_centered column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        type: "h2",
        class: "text-center",
        localizedKey: "backoffice_ModificaDomandaRompiGhiaccio",
        text: "Modifica Domanda rompi ghiaccio"
      })
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_form_field, {
        type: "textarea",
        modelValue: _ctx.localModel.text,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.localModel.text = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("button", {
        class: "ung_button big",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "backoffice_Annulla",
          text: "Annulla"
        })
      ]),
      _createVNode("button", {
        class: "ung_button big",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.save()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "backoffice_Salva",
          text: "Salva"
        })
      ])
    ])
  ]))
}