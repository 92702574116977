
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class EditIceQuestionModal extends Vue {

    @Prop({
        default: () => new OM.IceQuestionModelVM()
    }) question: OM.IceQuestionModelVM;
    
    @Prop({
        type: [Function]
    }) callback: any;

    localModel: OM.IceQuestionModelVM = new OM.IceQuestionModelVM();

    created() {
        this.localModel = {...this.question};
    }

    save() {
        this.callback(this.localModel);
    }

    close() {
        this.$emit('close');
    }

}
