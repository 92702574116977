
import { Options, Vue } from 'vue-class-component';
import { DomandaRompighiaccioConfigClient } from '@/services/Services';
import EditIceQuestionModal from '../modals/editIceQuestionModal.vue';
import * as OM from '@/Model';

@Options({})
export default class domandeList extends Vue {

    items: OM.DomandaRompighiaccioConfig[] = [];
    key: number = 0;

    created(){
        this.init();
    }

    init() {
        DomandaRompighiaccioConfigClient.getAll()
        .then(x => {
            this.key = Math.random();
            this.items = x;
        })
    }

    disable(item: OM.DomandaRompighiaccioConfig){
        DomandaRompighiaccioConfigClient.disableById(item.identifier)
        .then(x => {
            this.init();
        })
    }

    editIceQuestion(item: OM.DomandaRompighiaccioConfig) {
        if(!item)
            item = new OM.DomandaRompighiaccioConfig();

        var vm = new OM.IceQuestionModelVM();
        vm.identifier = item.identifier;
        vm.text = item.text;

        this.$opModal.show(EditIceQuestionModal, {
            question: vm,
            callback: (updatedItem: OM.IceQuestionModelVM) => {
                DomandaRompighiaccioConfigClient.edit(updatedItem)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                });
            }
        })
    }

}
